<template>
  <svg
    class="map country mexico"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 309.9 222.8"
  >
    <g class="hover">
      <path
        :class="`hover mexico ${isActive('Mexico')}`"
        data-loc="70,135"
        @click="quickViewShop('mexico')"
        d="M112.9,150.1l2.2-.2-3.2,5.7-1.8,4.6-1.8,8.6-1.1,3.1.4,3.5,1.3,3.2.4,4.9,3,4.8.8,3.7,1.7,3.1,5.7,1.7,1.9,2.7,5.2-1.8,4.3-.6,4.4-1.2,3.6-1.1,3.9-2.6,1.8-3.7,1.2-5.4,1.2-1.9,4-1.7,6.1-1.5,4.9.3,3.4-.6,1.2,1.4-.6,3.1-3.5,3.8-1.8,3.9.9,1.1-1.2,2.8-2.1,5-1.2-1.7-1.1.1-1.1.1-2.5,3.9-.9-.8-.7.3-.1,1-5.2-.1h-5.2l-.5,3.6-2.5.1,1.8,2.1,1.9,1.5.5,1.4.8.4-.4,2.2h-7.2l-3.3,5.2.7,1.2-.8,1.5-.4,1.9-5.6-6.9-2.6-2.1-4.4-1.7-3.2.5-4.8,2.4-2.9.6-3.7-1.7-4.1-1.2-4.8-2.9-4.1-.9-5.9-3-4.3-3.1L80.4,201l-3.1-.4-5.4-2L70,195.7,64.6,192l-2.2-4-.8-3.2,1.9-.6-.3-1.8,1.6-1.7.4-2.2-1.5-2.9v-2.5l-1.3-3.3-3.8-6.4-4.6-5-1.9-4L48,151.8l-.7-1.6,1.7-3.9-2.4-1.5-2.5-3.2-.2-4.4-2.8-.6-2.3-3.3-1.7-3.2.3-2-1.5-4.8-.3-4.9.8-2.5-3.1-2.6-1.9.3L29,111.9l-1.8,2.6-.1,3-1,4.9,1,2.6,2.8,4.4.4,1.6.7.4.1,2.2,1-.1v4.2l1.3,1.6.5,2.3,2.7,3.2.4,6,1,2.8.9,3-.3,3.4,2.6.2,1.6,2.9,1.5,2.9-.3,1.2-2.8,2.3h-1l-.7-3.9-2.9-3.7-3.4-3.1-2.5-1.6,1.2-4.7-.1-3.5-2.1-2-3.1-2.8-.9.8-1-1.7-3-1.5L19.5,138l.5-.4,2.1.3,2.7-2.4,1-2.9L22.9,128l-2.6-1.7-.8-4-.6-4.3-.8-5.1-.2-5.8,6.3-.5,7.1-.7-.9,1.3,7,3.1,10.9,4.5H63.4l.8-2.7h9.4l1.3,2.3,2.1,2.1,2.4,2.8.8,3.3.4,3.6,2.3,1.9,4,1.9,4.8-5,4.5-.2,3.2,2.6,1.6,4.4.9,3.8,2.4,3.6.2,4.5.9,3,3.9,2,3.6,1.4Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M203.1,8.7l-6.1,2-4.7,2.5-4.6,2.7-.5.9,5.7-1.3,2.1,2.1,4.6-1.5,4.9-2.1,5.4-2.1-3.1,3.3,2.5.8,2.5,2.4,5.1-1.4,5.1-.5.3,1.8,1.5.2,1.2.2,1.5,2.5-4.7.6h-.1l-3.7-.7-4.5,1.2-3.7.6L205.1,27l-3,2.3.4.7,5.5-4.1h.7L204,30.8l-2.9,4.4-2.5,3.6-.6,3.1-.8,1.5-.6,1.7.1,3.3.3.5,1.8-.1,1.6-.7,1.4-.8,3.3-3.1,1.8-4.2-.1-3.9,1.4-2.7,2.6-3.1,2.1-2.2,2.7-1.5-.4,2.1,2.2-3.1,1.3-.6,1.7-2.4,3.8,1.3,2.8,2.4-.8,2.9-1.6,2.9-3.8,2.5-.4,1.6h1l4.3-2.7,1.6.6-.5,3.7-.7,2.6-3.7,3.5-2,2.2-2.7,2.4,2.7,1.3,2.5.4,4-.9,3.7-1.7,3-.9,4.6-1.8,5.8-3.8.1-.6.3-1.9,2.7-.8,3.9.3,4,.5,4.6-2.1.6-2.5-.2-.9,6.8-4.4,2.7-1.1,7.8-.1h9.3l1.1-1.5,1.7-.3,2.5-1,2.8-2.9,3.2-4.9,5.5-4.7,1.1,1.6,3.7-1,1.6,1.8-2.9,8.5,2.2,3.6.2,2.1-6.4,3-6,2.2-6,1.9-4,3.8-1.3,1.4-1.2,3.4.7,3.3,2.1.2.2-2.3,1.1,1.4-1,1.8-3.8,1-2.5-.1L277,50.9l-2.3.3-3.1.3-5,1.9,8.1-1.2,1.1,1.2-7.9,1.9h-3.3l.4-.8-2.1,1.8,1.4.3-2.5,4.6-5.3,4.9.1-1.7-1.1-.3-1.2-1.6V66l1,1.1-.6,2.4L252.3,72l-4.5,5.1-.4-.2,2.9-4.4-2-2.4,1-5.4-1.9,2.8v4.1l-3.2-1,3,2-1.5,6.1,1.4.5v2.2l-1,6.4-4.6,4.7-6.1,1.9L231,98.2l-2.8.4-3.4,2.4-1.3,2.1-6.9,4.2-3.8,3.1-3.5,3.8-1.9,4.5v4.5l.6,5.5,1.5,4.5L209,136l1.3,7.4-1,4.4-.6,2.5-2,3.9-1.8.8-2.6-.8-.4-2.8-1.8-1.5-2-5.5-1.6-4.9-.4-2.5,2-4.3-.8-3.5-3.1-5.4-1.9-1-6.1,3-.9-.4-2-3-3-1.6-6.4.9-4.6-.8-4.3.5-2.5,1,.6,1.7-.7,2.6.8,1.3-1.2.8-1.8-.9-2.3,1.2-3.9-.2-3.3-3.4-4.9.8-3.6-1.5-3.5.5-5,1.5-6.1,4.7-6.1,2.8-3.7,3-1.9,2.9-1,4.5-.4,3,.6,2.2-2.2.2-3.6-1.4-3.9-2-.9-3-.2-4.5-2.4-3.6-.9-3.8-1.6-4.4-3.2-2.6-4.5.2-4.8,5-4-1.9-2.3-1.9-.4-3.6-.8-3.3L77,116.5l-2.1-2.1-1.3-2.3H64.2l-.8,2.7H48.3l-10.9-4.5-7-3.1.9-1.3-7.1.7-6.3.5.3-3.2-2.1-3.7-2.2-.8.1-1.8-2.9-.4L9.9,95.5l-4.8-.6-.9-1.1L5,90.3,2.5,83.9,2,75l.9-1.5L1.6,71.4.1,66l1.8-5.2L1,57.3,4.9,52l2.8-5.4,1.1-4.9,5.5-6,4-5.7,4-5.7,4.3-8.5,1.8-5.3.4-2.9,1.4-1.3L36,8.5l-1,5.9,2.2-1.7,2.5-5.1,1.6-5.1H178.4L179.7.1h1.7l-.9,3.4,1,1,3.3.4,4.6,1,3.9,1.9,4.4-.8Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
    </g>
    <g
      class="pin"
      :transform="`translate(${targetLoc[0]} ${targetLoc[1]}) scale(1.1)`"
    >
      <path
        fill="#000"
        d="M17.54,3.52A9.9,9.9,0,0,0,12,.21,9.91,9.91,0,0,0,.22,7.65,9.48,9.48,0,0,0,0,10.18c-.16,5.18,3.92,10,7.7,15,.93,1.15,2.23,2.42,2.29,2.57l2.08-2.46C16.82,19.08,23.74,10.05,17.54,3.52Zm-2.86,7.79A4.88,4.88,0,0,1,9,15.16l-.12,0a5,5,0,0,1-3.75-5.9,4.87,4.87,0,0,1,5.78-3.82A5,5,0,0,1,14.68,11.31Z"
      ></path>
      <path
        fill="#fff"
        d="M11.55,2.93A7.37,7.37,0,0,0,2.82,8.77a7.6,7.6,0,0,0,5.67,9l.18,0a7.37,7.37,0,0,0,8.55-5.87A7.59,7.59,0,0,0,11.55,2.93Zm3.13,8.38A4.88,4.88,0,0,1,9,15.16l-.12,0a5,5,0,0,1-3.75-5.9,4.87,4.87,0,0,1,5.78-3.82A5,5,0,0,1,14.68,11.31Z"
      ></path>
      <text :x="`25`" y="20" class="small">
        {{ country }}
      </text>
    </g>
    <text
      x="70"
      y="135"
      :class="`small ${isActive('Mexico')}`"
      style="font-size: 6px"
    >
      Mexico
    </text>
  </svg>
</template>
<script>
export default {
  name: "Mexico",
  data() {
    return {
      targetLoc: [0, 0],
    };
  },
  props: {
    country: String,
  },
  mounted() {
    const target = document.querySelector(".map .active");
    this.targetLoc = [
      target.dataset.loc.split(",")[0],
      target.dataset.loc.split(",")[1],
    ];
    this.log("Map:#901", "Country", this.country, target);
  },
  methods: {
    isActive(query) {
      return query === this.country ? "active" : "";
    },
    quickViewShop(selection) {
      if (selection !== "shop") {
        this.$store.commit("saveFilterInStore", [selection]);
        this.$store.commit("saveActiveFilters", [1, 0, 0, 0]);
      }
      window.location = "#/user/shop";
    },
  },
};
</script>
